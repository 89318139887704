import Util from '../util/util.js';

export default class TextOnPath {
  constructor(svgEl) {
    this.DOM = {svg: svgEl};
    this.DOM.text = this.DOM.svg.querySelector('text');
    this.DOM.textPath = this.DOM.text.querySelector('textPath');
    this.pathLength = this.DOM.svg.querySelector('path').getTotalLength();
    this.svgRect = this.DOM.svg.getBoundingClientRect();
    this.positionX = this.svgRect.left + window.pageXOffset;
    this.winsize = {width: window.innerWidth, height: window.innerHeight};
    this.scrollVertical = window.innerWidth <= 1024;
    // Recalculate on window resize
    window.addEventListener('resize', () => {
      this.onResize();
    });
    this.resize()
    this.startOffset = {
      value: this.computeOffset(),
      amt: 0.12
    };
    this.startOffset.value = this.computeOffset();
    this.updateTextPathOffset();
    this.scroll = {
      value: this.scrollVertical ? window.pageYOffset : window.pageXOffset,
      amt: 0.017
    };
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        this.isVisible = entry.intersectionRatio > 0;
        if ( !this.isVisible ) {
          this.entered = false;
          // reset
          this.update();
        }
      });
    });
    this.observer.observe(this.DOM.svg);
    
    requestAnimationFrame(() => this.render());
  }
  onResize() {
    if(window.innerWidth <= 1024 && Util.isTouch()) {
      if(!this.inited) {
        this.resize();
      }
    }else {
      this.resize();
    }
  }
  resize() {
    this.inited = true;
    this.scrollVertical = window.innerWidth <= 1024;
    this.svgRect = this.DOM.svg.getBoundingClientRect();
    this.positionX = this.svgRect.left + window.pageXOffset;
    this.winsize = {width: window.innerWidth, height: window.innerHeight};
    if(this.scrollVertical) {
      this.positionX = this.svgRect.top + window.pageYOffset;
    }
  }
  computeOffset() {
    if(!this.scrollVertical) {
      return Util.map(this.positionX - window.pageXOffset, this.winsize.width, 0, 0, -this.pathLength * 0.5);
    }else {
      return Util.map(this.positionX - window.pageYOffset, this.winsize.height, 0, 0, -this.pathLength * 0.5);
    }
  }
  // Updates the text startOffset value
  updateTextPathOffset() {
    this.DOM.textPath.setAttribute('startOffset', this.startOffset.value);
  }
  update() {
    // Calculate and set the interpolated startOffset value
    const currentOffset = this.computeOffset();
    this.startOffset.value = !this.entered ? currentOffset : Util.lerp(this.startOffset.value, currentOffset, this.startOffset.amt);
    this.updateTextPathOffset();
    
    // SVG Filter related:
    // The current scroll value
    const currentScroll = this.scrollVertical ? window.pageYOffset : window.pageXOffset;
    // Interpolated scroll value
    this.scroll.value = !this.entered ? currentScroll : Util.lerp(this.scroll.value, currentScroll, this.scroll.amt);
    // Distance between the current and interpolated scroll value
    const distance = Math.abs(this.scroll.value - currentScroll);
    // Update the filter primitive attribute that changes as the scroll speed increases
    this.filterPrimitive && this.filterPrimitive.update(distance);
    
    if ( !this.entered ) {
      this.entered = true;
    }
  }
  render() {
    if ( this.isVisible ) {
      this.update();
    }
    // ...
    requestAnimationFrame(() => this.render());
  }
}