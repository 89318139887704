import '../styles/loading.scss'

import Util from "./util/util";

window.app = {};

export default class Loading {
  constructor(){
    this.el = document.getElementById('loading');
    this.number = this.el.querySelector('#loading-number');
    this.done = false;
    this.progress = this.progress.bind(this);

    this.event = new CustomEvent('customload');
    this.isMobile = window.innerWidth < 970;

    this.done = true
    this.close();

    setTimeout(() => {
      if(!this.done) this.open();
    }, 3000)
  }
  progress(prc) {
    if(this.number) this.number.innerHTML = `${Math.ceil(prc * 100)}%`;
  }
  open(){
    this.isOpen = true;
  }
  close(){
    Util.waitForAssets(document.documentElement, () => {
      const fonts = ['Open Sans', 'Telma'];
      Util.waitForFont(fonts, () => {
        this.isOpen = false;
        this.el.classList.add('hide');
        document.documentElement.setAttribute('data-custom-loaded', true);
        window.dispatchEvent(this.event);
      });
    });
  }
}
const loading = new Loading();