import '../styles/index.scss'
import Lenis from '@studio-freight/lenis'

import Util from './util/util';
import SmarText from './components/smart-text.js';
import TextOnPath from './components/text-on-path.js';

export default class Page {
  constructor(options) {
    this.options = options || {};
    this.pageName = this.options.page || 'home';
    window.pageName = this.pageName;
    this.init();

    Util.waitForLoader(this.pageReady);
  }
  init() {
    // dont't save scroll position on refresh
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    this.scrollVertical = window.innerWidth <= 1024;
    
    this.resize = this.resize.bind(this);
    this.paralax = this.paralax.bind(this);
    this.pageReady = this.pageReady.bind(this);

    this.isTouch = Util.isTouch();

    
    document.documentElement.setAttribute('data-browser', Util.getBrowser());
    document.documentElement.setAttribute('data-touch', this.isTouch);

    window.addEventListener('resize', () => {
      this.resize();
    });

    this.lenis = new Lenis({
      orientation: this.scrollVertical ? 'vertical' : 'horizontal',
    })
    this.lenis.scrollTo(0, {
      duration: 0,
      ease: 'none',
    })
    this.scrollVelocity = {c: 0, t: 0}
    this.lastScroll = 0;

    this.lenis.on('scroll', (e) => {
      this.paralax(e)
    })

    const raf = (time) =>{
      this.lenis.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)

    this.paralax();
    this.setOS();
  }
  initIntro() {
    const header = document.getElementById('header');
    main.classList.add('no-transition');
    header.classList.add('init-intro');
    const mediaContainers = Array.from(document.querySelectorAll('.media-container'));
    mediaContainers.forEach((item, i) => {
      if(i < 2) {
        const layers = Array.from(item.querySelectorAll('.media-layer'));
        layers.forEach((layer, j) => {
          const scale = 0.3 * j;
          layer.style.transform = `scale(${1 - scale})`;
        });
      }
    });
    this.lenis.stop();
  }
  intro() {
    main.classList.remove('no-transition');
    header.classList.remove('init-intro');
    const headerPath = document.querySelector('.text-path text');
    let introDone = false;
    const mediaContainers = Array.from(document.querySelectorAll('.media-container'));
    const x = { c: -70, t: 0 };
    const raf = () => {
      x.c += (x.t - x.c) * 0.05;
      const progress = (x.c + 70) / 70;
      
      headerPath.setAttribute('x', `${x.c}%`);
      mediaContainers.forEach((item, i) => {
        if(i < 2) {
          const layers = Array.from(item.querySelectorAll('.media-layer'));
          layers.forEach((layer, j) => {
            const scale = 0.3 * j * (i + 1) * (1 - Math.pow(progress, 5));
            layer.style.transform = `scale(${1 - scale})`;
          });
        }
      });
      if(!introDone)
        requestAnimationFrame(raf);
    }
    raf();
    setTimeout(() => {
      introDone = true;
      document.documentElement.classList.add('intro-done');
      this.lenis.start();
      this.lenis.scrollTo(0, {
        immediate: true,
        force: true,
      })
    }, 2000);
  }
  paralax(data) {
    if(!Util.isTouch()) {
      this.scrollVelocity.t = data ? data.velocity : 0
      this.scrollVelocity.c += (this.scrollVelocity.t - this.scrollVelocity.c) * 0.1
    }else if(data) {
      const velocity = Math.min(Math.abs((data.scroll - this.lastScroll)) * 0.9, 40);
      this.scrollVelocity.t = velocity;
      this.scrollVelocity.c += (this.scrollVelocity.t - this.scrollVelocity.c) * 0.1
      this.scrollVelocity.t -= this.scrollVelocity.t * 0.1;
    }

    const mediaContainers = Array.from(document.querySelectorAll('.media-container'));
    const velocity = this.scrollVelocity.c;
    mediaContainers.forEach((item) => {
      const layers = Array.from(item.querySelectorAll('.media-layer'));
      layers.forEach((layer, i) => {
        const scale = Math.min(Util.lerp(0, 0.2, Math.abs(velocity) * 0.02) * i, 0.9);
        if(i > 0) {
          layer.style.transform = `scale(${1 - scale})`;
        }
      });
    });
    this.scrollItems = Array.from(document.querySelectorAll(".fade-in-x, .fade-in, [data-scroll-item]"));
    this.scrollItems.forEach((item) => {
      let offset = item.getAttribute('data-scroll-offset') ? Number(item.getAttribute('data-scroll-offset')) : 80;
      if(this.scrollVertical) {
        offset = 0;
      }
      if (!item.classList.contains('show') && Util.isInViewportDom(item, offset)) {
        item.classList.add('show')
      }
    });
    this.lastScroll = data ? data.scroll : this.lastScroll;
  }
  pageReady() {
    this.resize();
    this.initSmartText();
    this.initTextPath();
    this.initMotionPath();
    this.initIntro();
    setTimeout(() => {
      this.intro();
    }, 1000)
  }
  setOS() {
    let os = "osx";
    if (navigator.platform) {
      if (navigator.platform.indexOf("Win") > -1) {
        os = "windows";
      } else if (navigator.platform.indexOf("Mac") > -1) {
        os = "osx";
      } else if (navigator.platform.indexOf("Linux") > -1) {
        os = "linux";
      }
    } else {
      if (navigator.userAgent.indexOf("Windows") > -1) {
        os = "windows";
      } else if (navigator.userAgent.indexOf("Mac") > -1) {
        os = "osx";
      } else if (navigator.userAgent.indexOf("Linux") > -1) {
        os = "linux";
      }
    }
    document.documentElement.setAttribute("data-os", os);
  }
  initSmartText() {
    const smartTexts = Array.from(document.querySelectorAll('.smart-text'));
    smartTexts.forEach((item) => {
      new SmarText({$el: item});
    });
  }
  initTextPath() {
    this.textPaths = Array.from(document.querySelectorAll('.text-path')).map(item => {
      return {
        svg: item,
        x: {c: 0, t: 0},
      }
    });
    this.textPaths.forEach((item, i) => {
      const textPath = item.svg.querySelector('textPath');
      const $text = textPath.parentElement;
      const path = document.querySelector(textPath.getAttribute('href'));
      const pathLen = path.getTotalLength();
      const textLen = textPath.innerHTML.length;
      let m = 0.15;
      if(i === 0) {
        m = 0.055;
      }else if(i === 2) {
        m = 0.065;
      }
      m *= 3;
      const n = Math.ceil(pathLen / textLen) * m;
      let text = textPath.innerHTML;
      let finalHTML = '';
      for (let i = 0; i < n; i++) {
        let l = (i > 0 ? ' - '  : '') + text;
        finalHTML += `<tspan>${l}</tspan>`;
      }
      textPath.innerHTML = finalHTML;
      if(i !== 2) 
        item.textOnPath = new TextOnPath(item.svg);
    })
  }
  initMotionPath() {
    
  }
  updateRotatedText() {
    const images = document.querySelectorAll('.media-container');
    images.forEach(image => {
      const img = image.querySelector('img');
      if(img) {
        const ratio = img.naturalHeight / img.naturalWidth;
        image.style.setProperty('--ratio', ratio);
      }
    })
    if(this.scrollVertical) return;
    const paragraphs = document.querySelectorAll('.media-with-text p, #header-title-container');
    document.querySelectorAll('.media-with-text').forEach(item => {
      item.classList.add('width-auto');
    })
    paragraphs.forEach((item) => {
      item.classList.add('no-transform');
      
      const parentR = item.closest('.media-with-text') ? item.parentElement.querySelector('.media-container').getBoundingClientRect() : item.parentNode.getBoundingClientRect();
      item.style.setProperty('--parent-width', `${parentR.width}px`);
      item.style.setProperty('--parent-height', `${parentR.height}px`);

      const rect = item.getBoundingClientRect();
      item.style.setProperty('--width', `${rect.width}px`);
      item.style.setProperty('--height', `${rect.height}px`);
      item.classList.remove('no-transform');
    });
    document.querySelectorAll('.media-with-text').forEach(item => {
      item.classList.remove('width-auto');
      item.style.width = `${item.scrollWidth}px`;
    })
  }
  resize() {
    this.isTouch = Util.isTouch();
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
  
    // Set the viewport size for css
    const vh = this.winH * 0.01;
    this.isMobile = window.innerWidth <= 1024;
    const scrollVertical = window.innerWidth <= 1024;
    if(this.scrollVertical !== scrollVertical) {
      this.lenis.destroy();
      this.lenis = new Lenis({
        orientation: scrollVertical ? 'vertical' : 'horizontal',
      })
    }
    this.scrollVertical = scrollVertical;

    if (this.isMobile && !this.mobileInitialized) {
      this.mobileInitialized = true;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    } else if (!this.isMobile) {
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    const setContainer = (name) => {
      const container = document.createElement('div');
      container.classList.add(name);
      document.body.append(container);
      const rect = container.getBoundingClientRect();
      container.remove();
      document.documentElement.style.setProperty(
        `--${name}-margin`,
        `${rect.left}px`
      );
      document.documentElement.style.setProperty(
        `--${name}-width`,
        `${rect.width}px`
      );
    }
    setContainer('container');
    this.updateRotatedText();
  }
}

const page = new Page();